<script>
  import Heading from "./Heading.svelte";

  export let about;
</script>

<article class="brief-section">
  <Heading headingType="h2">About</Heading>
  <p class="brief">
    {about.brief}
  </p>
</article>

<style>
  .brief {
    padding: 0.5rem 0;
  }
</style>
