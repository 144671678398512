<script>
  import Heading from "./Heading.svelte";

  export let skills;
</script>

<article class="section">
  <Heading headingType="h2" highlightFactor="0.9">Skills</Heading>
  <ul class="skills-list">
    {#each skills as skill}
      <li class="skill">
        <span class:highlighted={skill === "React JS" || skill === "Node JS"}>
          {skill.title}
        </span>
      </li>
    {/each}
  </ul>
</article>

<style>
  .section {
    padding: 20px 0;
  }

  .skills-list {
    padding: 0.5rem 0 0.5rem 0rem;
    margin: 0;
    list-style: none;
    margin: 0;
  }

  .skill {
    display: flex;
    justify-content: space-between;
    padding-right: 1.5rem;
    padding-bottom: 0.5rem;
  }
</style>
