<script>
  import Heading from "./Heading.svelte";

  export let educations;
</script>

<article class="education">
  <Heading highlightFactor="0.9" headingType="h2">Education</Heading>

  <ul class="educations-list">
    {#each educations as education}
      <li class="education-item">
        <div class="div">
          <h3 class="degree">
            {education.degree}
            {#if education.fieldOfStudy !== ""}
              in {education.fieldOfStudy}
            {/if}
          </h3>
          <div class="about-institution">
            <p>{education.institution.name}</p>
            <p>{education.institution.location}</p>
          </div>
        </div>
        <p class="duration">{education.start} - {education.end}</p>
      </li>
    {/each}
  </ul>
</article>

<style>
  .education {
    padding: 20px 0;
  }

  .educations-list {
    padding: 0.5rem 0;
    margin: 0;
    list-style: none;
  }

  .education-item {
    padding: 10px 0;
  }

  .about-institution {
    font-size: 0.9rem;
  }

  .degree {
    font-weight: 600;
    font-size: 1.1rem;
    font-family: "PT Serif", serif;
  }

  .duration {
    font-size: 0.75rem;
  }
</style>
