<script>
  export let headingType;
  export let highlightFactor = 1;
</script>

<div style={`opacity: ${highlightFactor}`}>
  {#if headingType === "h1"}
    <h1>
      <slot />
    </h1>
  {:else if headingType === "h2"}
    <h2>
      <slot />
    </h2>
  {:else if headingType === "h3"}
    <h3>
      <slot />
    </h3>
  {:else if headingType === "h4"}
    <h4>
      <slot />
    </h4>
  {:else if headingType === "h5"}
    <h5>
      <slot />
    </h5>
  {/if}
</div>

<style>
  h1 {
    font-size: 2rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    margin: 0;
    font-family: "PT Serif", serif;
    padding: 0;
  }

  h2 {
    font-size: 1.75rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    font-family: "PT Serif", serif;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-family: "PT Serif", serif;
  }

  h4 {
    color: #999;
    letter-spacing: 0.5px;
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  h5 {
    color: rgb(35, 35, 35);
    letter-spacing: 0.5px;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
  }
</style>
