<script>
  import About from "./components/About.svelte";
  import Contact from "./components/Contact.svelte";
  import Skills from "./components/Skills.svelte";
  import WorkExperience from "./components/WorkExperience.svelte";
  import Education from "./components/Education.svelte";
  import SideProjects from "./components/SideProjects.svelte";
  import Heading from "./components/Heading.svelte";

  let about = {
    name: "Saurabh Sharma",
    jobTitle: "Senior Software Developer",
    link: "itsjzt.com",
    location: "Delhi, IN",
    brief:
      "A Senior Software Developer with 5+ years of experience in full-stack development, specializing in scalable SaaS platforms using Node.js, React, and serverless architectures. Proven ability to optimize system performance and user engagement, driving 20% cost savings, improving load times by up to 30%.",
  };

  let experiences = [
    {
      company: { name: "Codeword Tech", location: "Gurugram" },
      roles: [
        {
          title: "Senior Software Developer",
          start: "March 2019",
          end: "Present",
          points: [
            "Worked on backend & frontend of Macro campaign, a marketing suite for doing  paid ads cost-effectively. Customers report upto 40% cost saving in conversion ads and upto 65% savings in lead ads.",
            "The backend is powered by GraphQL for client-server communication, cron for scheduling automations and queues & workers for background processing. The frontend uses React js, a custom build design system, apollo for GraphQL communicating server and custom hooks.",
            "Led development of Macro outreach, a tool for e-commerce stores to reach their existing audiences through email newsletters, SMS, and WhatsApp. Powered using similar technologies as Macro campaign",
            "Led a cross-functional team of 3 developers, improving developer efficiency by 15% through agile task prioritization and close collaboration with stakeholders and beta users.",
            "Developed custom online stores (e.g., varunbahl.com, mayyurgirotra.com) leading to a 65% reduction in load time and a 15% increase in conversion rates through enhanced SEO and sales-oriented custom features",
            "Built a mobile app for the internal team to keep up with the daily sale of the brands we were managing.",
            "Worked on Grey Outline. Grey outline was an e-commerce site built from scratch using React.js, Next.js, GraphQL, Node.js and Typescript",
          ],
        },
        // {
        //   title: "Full Stack Developer",
        //   start: "June 2020",
        //   end: "Mar 2023",
        //   points: [
        //     "Built e-commerce store using modern web technologies for ekaya.in to improve their sales and customer experience. Ekaya saw a 15% YoY increase in sales with the new online store.",
        //     "Worked on 4 Shopify Apps (1) Simple Scroll to top, (2) Slick progress bar, (3) Superbar, and (4) Helpmagent.",
        //     "The technology stack of all Shopify apps includes CRA, React.js, Typescript, Apollo Client on the admin side, Svelte for storefront widget and Nodejs, GraphQL for the backend.",
        //   ],
        // },
        // {
        //   title: "Full Stack Intern",
        //   start: "January 2020",
        //   end: "July 2020",
        //   points: [
        //     "Worked on Grey Outline. Grey outline was an e-commerce site built from scratch using React.js, Next.js, GraphQL, Node.js, Typescript",
        //   ],
        // },
      ],
    },
  ];

  let educations = [
    {
      institution: {
        name: "IGNOU",
        location: "Delhi, India",
      },
      fieldOfStudy: "",
      degree: "Bachelor of Computer Applications",
      start: "2023",
      end: "2026",
    },
    {
      institution: {
        name: "DSEU",
        location: "Delhi, India",
      },
      fieldOfStudy: "Computer Engineering",
      degree: "Polytechnic",
      start: "2016",
      end: "2019",
    },
  ];
  let skills = [
    { title: "React JS", rating: 8 },
    { title: "Typescript", rating: 8 },
    { title: "Node JS", rating: 8 },
    { title: "Fullstack Development", rating: 8 },
    { title: "Postgres & SQL", rating: 8 },
    { title: "AWS Lambda & Serverless", rating: 8 },
    { title: "Graph QL", rating: 8 },
    { title: "Next JS", rating: 8 },
    { title: "Svelte JS", rating: 8 },
    { title: "HTML & CSS", rating: 8 },
    { title: "React Native", rating: 8 },
    { title: "Shopify Apps", rating: 8 },
    { title: "Python", rating: 8 },
    { title: "DevOps", rating: 8 },
  ];

  let sideProjects = [
    {
      title: "Redlang Programming Language",
      description:
        "Redlang is a lightweight interpreted programming language inspired by JavaScript and Python. The interpreter is built in Typescript. It supports all common programming language constructs like variables, functions, OOPs and closures. I learned a lot about processing strings efficiently and compiler design in general while working on this project. Source code is available on GitHub.",
      link: "github.com/itsjzt/redlang",
    },
    {
      title: "PlainChat | Whatsapp Support",
      description:
        "Plainchat is a simplified way to do customer support using whatsapp. I made it for some friend, turned out a lot of people wanted something similar so I published it on shopify app store. I have 2+ years of experience developing shopify apps which definitely helped there. App is listed on shopify and early feedback is positive",
      link: "apps.shopify.com/plainchat-whatsapp-support",
    },
  ];

  let contacts = [
    { name: "Phone", value: "+91 8076-623-211", icon: "/icons/phone.svg" },
    { name: "Email", value: "hey@itsjzt.com", icon: "/icons/mail.svg" },
    {
      name: "Linked In",
      value: "linkedin.com/in/itsjzt",
      icon: "/icons/linkedin.svg",
    },
    {
      name: "Github",
      value: "github.com/itsjzt",
      icon: "/icons/github.svg",
    },
  ];
</script>

<div class="wrapper">
  <header>
    <div>
      <Heading headingType="h1">{about.name}</Heading>
      <Heading headingType="h4">{about.jobTitle}</Heading>
      <div>
        {about.link} | {about.location}
      </div>
    </div>
  </header>
  <div class="layout">
    <main>
      <About {about} />
      <WorkExperience {experiences} />
      <SideProjects {sideProjects} />
    </main>
    <aside>
      <Contact {contacts} />
      <Skills {skills} />
      <Education {educations} />
    </aside>
  </div>
</div>

<style>
  :global(body) {
    background: #fafafa;
  }

  .wrapper {
    width: 100%;
    padding-top: 8px;
    max-width: 980px;
    padding-left: 30px;
    margin: 0 auto;
    /* background: #fafafa; */
    /* border: solid 1px #eaeaea; */
  }

  header {
    padding: 8px;
  }

  .layout {
    display: grid;
  }

  main {
    padding: 8px;
    padding-right: 16px;
  }

  aside {
    padding: 8px;
    padding-left: 16px;
  }

  @media screen and (min-width: 768px) {
    .layout {
      grid-template-rows: auto;
      grid-template-columns: 2fr 1fr;
    }

    header {
      padding: 10px;
    }

    main {
      padding: 8px;
      padding-right: 30px;
    }

    aside {
      padding: 8px;
      padding-left: 30px;
    }

    aside {
      border-left: solid 1px #ddd;
    }
  }
</style>
